import { Route, Routes, useLocation, useNavigate } from "react-router";
import "./App.css";
import Login from "./Components/User/Login/Login";
import AdminLogin from "./Components/Admin/Login/AdminLogin";
import Dashboard from "./Components/Dashboard/Dashboard";
import AllClaims from "./Components/Admin/Claims/AllClaims";
import AcceptedClaims from "./Components/Admin/Claims/AcceptedClaims";
import RejectedClaims from "./Components/Admin/Claims/RejectedClaims";
import ArchivedClaim from "./Components/Admin/Claims/Archived_Claim/ArchivedClaim";

import ViewRsa from "./Components/Admin/Participants/ViewRSAs";

import ProfileAdmin from "./Components/Admin/ProfileAdmin/ProfileAdmin";

import AddProduct from "./Components/Admin/Products/AddProduct";
import AddProductSize from "./Components/Admin/Products/AddProductSize";
import ViewProduct from "./Components/Admin/Products/ViewProduct";
import ReportsHistory from "./Components/Admin/Reports/ReportsHistory";

import AddNewsletter from "./Components/Admin/Newsletter/AddNewsletter";
import ManageNewsletter from "./Components/Admin/Newsletter/ManageNewsletter";

import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { bindActionCreators } from "redux";
import { actionCreaters } from "./Redux";
import Welcome from "./Components/User/Welcome/Welcome";
import PrevClaims from "./Components/User/Claims/PrevClaims";

import PrivacyStatement from "./Components/PrivacyStatement/PrivacyStatement";
import ProfileInfo from "./Components/Account/ProfileInfo";

import ViewDetails from "./Components/User/Claims/ViewDetails";
import FileClaims from "./Components/User/Claims/FileClaims";
import ForgotPass from "./Components/User/ForgotPassword/ForgotPass";
import Registration from "./Components/User/Registration/Registration";
import TermCondition from "./Components/User/Registration/TermCondition";
import ChangePass from "./Components/User/ChangePass/ChangePass";
import CheckForgetToken from "./Components/User/ForgotPassword/CheckForgetToken";
import RegPrivacyStatement from "./Components/User/Registration/RegPrivacyStatement";
import ViewDetail from "./Components/Dashboard/ViewDetail";

import AdminForgotPass from "./Components/Admin/AdminForgotPass/AdminForgotPass";
import CheckAdminForgetToken from "./Components/Admin/AdminForgotPass/CheckAdminForgetToken";
import AdminChangePass from "./Components/Admin/AdminForgotPass/AdminChangePass";
import EditUser from "./Components/Admin/Participants/EditUser";
import UpdateNewsletter from "./Components/Admin/Newsletter/UpdateNewsletter";

import EditProduct from "./Components/Admin/Products/EditProduct";
import ViewRSAsDeactivate from "./Components/Admin/Participants/ViewRSAsDeactivate";
import NotFound from "./Components/NotFound";
import AddRetailers from "./Components/Admin/Retailers/AddRetailers";
import ViewRetailers from "./Components/Admin/Retailers/ViewRetailers";
import EditRetailers from "./Components/Admin/Retailers/EditRetailers";
import ViewManager from "./Components/Admin/Participants/ViewManager";
import ViewManagerDeactivate from "./Components/Admin/Participants/ViewManagerDeactivate";
import ViewDos from "./Components/Admin/SubAdmin/ViewDos";
import AddDos from "./Components/Admin/SubAdmin/AddDos";
import ArchivedDosClaim from "./Components/Admin/Claims/Archived_Claim/ArchivedDosClaim";
import AllClaimsDos from "./Components/Admin/Claims/AllClaimsDos";
import AcceptedClaimsDos from "./Components/Admin/Claims/AcceptedClaimsDos";
import RejectedClaimsDos from "./Components/Admin/Claims/RejectedClaimsDos";
import ViewAcceptedRsa from "./Components/Admin/ManageRsaDos/ViewAcceptedRsa";
import ViewAcceptedManagers from "./Components/Admin/AcceptedManagersDos/ViewAcceptedManagers";
import AddUserStates from "./Components/Admin/UserStates/AddUserStates";
import ViewAllUserStates from "./Components/Admin/UserStates/ViewAllUserStates";
import AddUserCities from "./Components/Admin/UserCities/AddUserCities";
import ViewAllUserCities from "./Components/Admin/UserCities/ViewAllUserCities";
import EditUserCity from "./Components/Admin/UserCities/EditUserCity";
import EditUserState from "./Components/Admin/UserStates/EditUserState";

function App() {
  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);
  const navigate = useNavigate();
  const locat = useLocation();
  const state = useSelector((state) => state.stateVals);
  // const { state, dispatch: ctxDispatch } = useContext(UserContext);
  const { id, accessToken, uType } = state;

  let decoded;

  useEffect(() => {
    // auto logout code start
    if (accessToken) {
      decoded = jwt_decode(accessToken);
      // console.log("expiry date:" + decoded.exp);
      // console.log("current date:" + Math.floor(new Date().getTime() / 1000));
      // var date = new Date(decoded.exp * 1000);
      // console.log(date.toLocaleTimeString("en-US"));
      // const current = new Date();
      // const time = current.toLocaleTimeString("en-US");
      // console.log(time);
    }

    if (
      accessToken &&
      uType !== "admin" &&
      uType !== "dos" &&
      uType !== "ndos" &&
      uType !== "1099"
    ) {
      if (Math.floor(new Date().getTime() / 1000) > decoded.exp) {
        userActions.logOut(null);
        Swal.fire({
          title: "Expired!",
          text: "Your Login Session expired!",
          icon: "error",
          confirmButtonText: "Login Again",
        });
        navigate("/");
      }
      if (
        locat.pathname === "/" ||
        locat.pathname === "/forgot-pass" ||
        locat.pathname === "/registration" ||
        locat.pathname === "/forgot-pass/" ||
        locat.pathname === "/registration/" ||
        locat.pathname === "/register-statement" ||
        locat.pathname === "/register-statement/"
      ) {
        navigate("/welcome");
      }
    } else {
      if (
        locat.pathname !== "/forgot-pass" &&
        locat.pathname !== "/forgot-pass/" &&
        locat.pathname !== "/forgot-token" &&
        locat.pathname !== "/change-pass" &&
        locat.pathname !== "/term-and-conditions" &&
        locat.pathname !== "/register-statement" &&
        locat.pathname !== "/register-statement/" &&
        locat.pathname !== "/registration" &&
        locat.pathname.indexOf("admin") <= -1
      ) {
        navigate("/");
      }
    }

    // admin login conditions
    const callFun = window["onUrlChange"];
    if (
      accessToken &&
      uType !== "rsa" &&
      uType !== "manager" &&
      uType !== "corporate" &&
      uType !== "1099"
    ) {
      // auto logout code start
      if (Math.floor(new Date().getTime() / 1000) > decoded.exp) {
        userActions.logOut(null);
        Swal.fire({
          title: "Expired!",
          text: "Your Login Session expired!",
          icon: "error",
          confirmButtonText: "Login Again",
        });
        navigate("/admin");
      }
      // auto logout code end
      if (
        locat.pathname === "/admin" ||
        locat.pathname === "/admin/" ||
        locat.pathname === "/admin/forgot-pass" ||
        locat.pathname === "/admin/forgot-pass/"
      ) {
        callFun();
        navigate("/admin/dashboard");
      }
    } else {
      if (
        locat.pathname !== "/admin/forgot-pass" &&
        locat.pathname !== "/admin/forgot-token" &&
        locat.pathname !== "/admin/change-pass" &&
        locat.pathname !== "/admin/forgot-pass/" &&
        locat.pathname !== "/admin/change-pass/" &&
        locat.pathname.indexOf("admin") >= 1
      ) {
        navigate("/admin");
      }
    }
  }, [locat.pathname]);

  const MoveToDashboard = () => {
    useEffect(() => {
      window.location.href = "/admin/dashboard";
    }, []);
    return <></>;
  };

  return (
    <Routes>
      <Route path="/" element={<Login />} />

      <Route path="/welcome" element={<Welcome />} />
      <Route path="/prev-claims" element={<PrevClaims />} />

      <Route path="/privacy-statement" element={<PrivacyStatement />} />
      <Route path="/profile-info" element={<ProfileInfo />} />
      <Route path="/claim-detail/:id" element={<ViewDetails />} />
      <Route path="/submit-claim" element={<FileClaims />} />
      <Route path="/forgot-pass" element={<ForgotPass />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/term-and-conditions" element={<TermCondition />} />
      <Route path="/change-pass" element={<ChangePass />} />
      <Route path="/forgot-token" element={<CheckForgetToken />} />
      <Route path="/register-statement" element={<RegPrivacyStatement />} />

      {/* admin routing here  */}
      <Route path="/admin" element={<AdminLogin />} />
      <Route path="/admin/forgot-pass" element={<AdminForgotPass />} />
      <Route path="/admin/forgot-token" element={<CheckAdminForgetToken />} />
      <Route path="/admin/change-pass" element={<AdminChangePass />} />
      <Route path="/admin/profile-admin" element={<ProfileAdmin />} />
      <Route path="/admin/dashboard" element={<Dashboard />} />
      <Route path="/admin/dashboard/view-detail/:id" element={<ViewDetail />} />
      <Route
        path="/admin/claims"
        element={uType === "dos" ? <MoveToDashboard /> : <AllClaims />}
      />
      <Route
        path="/admin/accepted-claim"
        element={uType === "dos" ? <MoveToDashboard /> : <AcceptedClaims />}
      />
      <Route
        path="/admin/rejected-claim"
        element={uType === "dos" ? <MoveToDashboard /> : <RejectedClaims />}
      />
      <Route
        path="/admin/archived-claim"
        element={uType === "dos" ? <MoveToDashboard /> : <ArchivedClaim />}
      />
      <Route
        path="/admin/archived-dos-claim"
        element={uType === "dos" ? <ArchivedDosClaim /> : <MoveToDashboard />}
      />

      <Route
        path="/admin/view-rsa"
        element={uType === "dos" ? <MoveToDashboard /> : <ViewRsa />}
      />
      <Route
        path="/admin/view-deactivated-rsa"
        element={uType === "dos" ? <MoveToDashboard /> : <ViewRSAsDeactivate />}
      />

      <Route
        path="/admin/view-manager"
        element={uType === "dos" ? <MoveToDashboard /> : <ViewManager />}
      />
      <Route
        path="/admin/view-deactivated-manager"
        element={
          uType === "dos" ? <MoveToDashboard /> : <ViewManagerDeactivate />
        }
      />

      <Route
        path="/admin/edit/:type/:id"
        element={uType === "dos" ? <MoveToDashboard /> : <EditUser />}
      />

      <Route
        path="/admin/add-product"
        element={uType === "dos" ? <MoveToDashboard /> : <AddProduct />}
      />
      <Route
        path="/admin/add-product-size"
        element={uType === "dos" ? <MoveToDashboard /> : <AddProductSize />}
      />
      <Route
        path="/admin/view-product"
        element={uType === "dos" ? <MoveToDashboard /> : <ViewProduct />}
      />
      <Route
        path="/admin/reports-history"
        element={uType === "dos" ? <MoveToDashboard /> : <ReportsHistory />}
      />

      <Route
        path="/admin/add-newsletter"
        element={uType === "dos" ? <MoveToDashboard /> : <AddNewsletter />}
      />
      <Route
        path="/admin/manage-newsletter"
        element={uType === "dos" ? <MoveToDashboard /> : <ManageNewsletter />}
      />

      <Route
        path="/admin/update-newsletter/:id"
        element={uType === "dos" ? <MoveToDashboard /> : <UpdateNewsletter />}
      />

      <Route
        path="/admin/edit-product/:id"
        element={uType === "dos" ? <MoveToDashboard /> : <EditProduct />}
      />

      <Route
        path="/admin/add-store"
        element={uType === "dos" ? <MoveToDashboard /> : <AddRetailers />}
      />

      <Route
        path="/admin/view-store"
        element={uType === "dos" ? <MoveToDashboard /> : <ViewRetailers />}
      />
      <Route
        path="/admin/edit-store/:id"
        element={uType === "dos" ? <MoveToDashboard /> : <EditRetailers />}
      />
      <Route
        path="/admin/add-user-state"
        element={uType === "dos" ? <MoveToDashboard /> : <AddUserStates />}
      />
      <Route
        path="/admin/view-user-states"
        element={uType === "dos" ? <MoveToDashboard /> : <ViewAllUserStates />}
      />
      <Route
        path="/admin/add-user-city"
        element={uType === "dos" ? <MoveToDashboard /> : <AddUserCities />}
      />
      <Route
        path="/admin/view-user-cities"
        element={uType === "dos" ? <MoveToDashboard /> : <ViewAllUserCities />}
      />

      <Route
        path="/admin/edit-user-state/:id"
        element={uType === "dos" ? <MoveToDashboard /> : <EditUserState />}
      />
      <Route
        path="/admin/edit-user-city/:id"
        element={uType === "dos" ? <MoveToDashboard /> : <EditUserCity />}
      />
      <Route
        path="/admin/add-dos"
        element={uType === "dos" ? <MoveToDashboard /> : <AddDos />}
      />
      <Route
        path="/admin/view-dos"
        element={uType === "dos" ? <MoveToDashboard /> : <ViewDos />}
      />

      <Route
        path="/admin/dos-latest-claims"
        element={uType === "dos" ? <AllClaimsDos /> : <MoveToDashboard />}
      />
      <Route
        path="/admin/accepted-claim-dos"
        element={uType === "dos" ? <AcceptedClaimsDos /> : <MoveToDashboard />}
      />
      <Route
        path="/admin/rejected-claim-dos"
        element={uType === "dos" ? <RejectedClaimsDos /> : <MoveToDashboard />}
      />
      <Route
        path="/admin/view-accepted-rsa"
        element={uType === "dos" ? <ViewAcceptedRsa /> : <MoveToDashboard />}
      />
      <Route
        path="/admin/view-accepted-manager"
        element={
          uType === "dos" ? <ViewAcceptedManagers /> : <MoveToDashboard />
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
